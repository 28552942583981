.RootUnique {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: 1440px;
    padding-top: 100px;
    padding-bottom: 100px;

}

@media (max-width: 991px) {
    .RootUnique {
        height: auto;
        padding-top: 10%;
        padding-bottom: 10%;
    }
}