.gridFaqContainer {
   display: flex;
   align-items: center;
   justify-content: center;
}

.faqSectionRow {
   width: 100%;
   margin: 0 auto !important;
}

.faqCol {
   color: #000;
   height: fit-content;
   padding: 0;
   text-align: left;
   // margin-top: 100px;
}

.faqContainer {
   display: flex;
   justify-content: center;
}

.contentFaq {
   margin: 0 auto;
}

.faqCol .list-group {
   margin-bottom: 50px;
}

.faqCol .list-group-item {
   border: none;
}

.accordionRow {
   display: flex;
   justify-content: center;
}

// TEXT
.contentFaq {}

.faqTitleBackground {
   background: transparent;
   margin-bottom: 100px;
}

.faqTitleBackground .titleLeftBorder {
   left: 200px;
}

.faqTitle {
   display: flex;
   align-items: center;
   background: transparent;
   text-transform: uppercase;
   color: #fff;
}

.faqTitle .h2Title {
   width: 100%;
}

.faqP {
   font-family: 'league-gothic';
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 130%;
}

/* BUTTONS STYLE */

.buttonCard {
   border-bottom: none !important;
   padding-left: 0 !important;
   padding-bottom: 0 !important;
   padding-top: 100px !important;
   margin-left: 0 !important;
   display: flex;
   justify-content: center;
   align-items: center;
}


.contentFaq .buttonsGroup {
   margin-top: 30px;
   display: flex;
}

.buttonsFaq {
   padding: 20px 60px;
   width: fit-content;
   height: fit-content;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   transition: 0.5s;
   letter-spacing: 1.82px;
   -webkit-text-decoration: none !important;

   text-decoration: none !important;

   font-family: 'league-gothic';
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   //  line-height: 75px;
   /* or 469% */

   display: flex;
   align-items: center;
   text-transform: uppercase;
   color: #fff;
}

.buttonsFaq span {}

// ACCORDION

.cardFaqContent {
   width: 100%;
   margin: 0 auto;
}

.cardFaqContent .card {
   border: 0;
   padding-left: 70px;
   margin-top: 50px;
   background-color: transparent;
   padding-bottom: 30px;
}

// .faqCardsAccordion {
//    border-bottom: 1px solid theme-color('primary') !important;
// }

.cardFaqContent .card:nth-child(1) {
   margin-top: 0;
}

.cardFaqContent .card-header {
   font-family: "league-gothic";
   letter-spacing: 1px;
   font-style: normal;
   font-weight: normal;
   font-size: 40px;
   color: #fff;

   border-bottom: none;

   display: flex;

   align-items: center;
   text-transform: uppercase;
}

.cardFaqContent .card-body {
   font-family: "Roboto";
   font-style: normal;
   font-weight: normal;
   font-size: 18px;

   color: #fff;

   mix-blend-mode: normal;
}

.courseContentIcon {
   cursor: pointer;
   position: absolute;
   left: 15px;
   background-image: url(../../../../assets/img/icons/FAQ---Twerkealo.svg);
   background-size: 100% 100%;
   background-position: center;
   background-repeat: no-repeat;
   padding: 10px 20px;
   color: #fff;
   font-size: 20px;
}

.faqContentIcon .fa-plus {}

@media (max-width: 991px) {
   .faqSectionRow {
      width: 90%;
      margin: 0 auto !important;
   }

   .faqTitleBackground .titleLeftBorder {
      left: 0;
   }

   .contentFaq {
      padding-top: 0;
      padding-bottom: 0;
   }

   .faqTitleBackground {
      margin-bottom: 3vw;
   }

   .cardFaqContent .card-header {
      font-weight: 900;
      font-size: 4vw !important;
      line-height: 5vw !important;
   }

   .faqCol .list-group {
      width: fit-content;
      margin: 0 auto;
   }

   .buttonCard {
      padding-top: 10vw !important;
   }


   .buttonsFaq {
      width: 80%;
   }

   .buttonsFaq {
      font-size: 3vw;
   }

   .textFaq {
      color: #000;
      font-size: 15.55px;
      font-family: 'league-gothic';
      font-weight: 400;
   }

   .faqRowResp {
      background-color: #000;
   }

   .faqColFirst {
      margin-bottom: 50px;
   }

   .faqAccFirstCol {
      margin-bottom: 50px;
   }

}

@media (max-width: 600px) {
   .cardFaqContent .card-header {
      font-size: 5vw !important;
      line-height: 6vw !important;
   }

   .cardFaqContent .card-body {
      font-size: 4vw !important;
      line-height: 5vw !important;
   }

   .courseContentIcon {
      font-size: 3vw;
      padding: 3vw 20px;
   }

   .buttonsFaq {
      padding-left: 0;
      padding-right: 0;
      font-size: 4vw;
   }
}

@media (max-width: 300px) {
   .cardFaqContent .card-header {
      font-size: 10px !important;
      line-height: 10px !important;
   }

   .cardFaqContent .card-body {
      font-size: 8px !important;
      line-height: 8px !important;
   }
}