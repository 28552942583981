.RootVideo {
  background-color: theme-color("bgColor");
  height: auto;
  max-width: none;
  padding-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 200px;
}

@media (max-width: 991px) {
  .RootVideo {
    height: auto;
    padding-bottom: 20%;
    padding-top: 0;

  }
}