/* BACKGROUND ROW */

.RootHome {
  height: 100vh;
  background-color: #000;
  // background-color: theme-color("bgColor");
  // background: url("../../../../assets/imgs/photos/homePage.png");
  // background-repeat: no-repeat;
  // background-position: center top;
  // background-size: cover;
}

/* CONTENT */
.RootHome-inside {
  display: flex;
  width: 100%;
}

@media (min-width: 2000px) and (max-height: 950px) {
  .RootHome {
    padding-top: 130px;
  }
}

@media (max-height: 800px) {
  .RootHome {
    padding-top: 130px;
  }
}

@media (max-width: 991px) {
  .RootHome {
    height: auto;
    padding: 200px 0 25vw 0;
    background-size: cover;
    background-position: center;
  }
}