.ForMeSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.forMeImg {
    width: 70%;
    transform: rotate(-3deg) !important;
    margin-top: 100px;
}

.forMeContent {
    // position: sticky !important;
    // top: 20px;
}

.forMeContent .pText {
    margin-top: 40px;
}


.forMeSubtitle {
    width: 80%;
    margin-top: 50px !important;
    margin: 0 auto;
    text-align: left;
}

.forMeContent .whiteLine {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media(max-width: 991px) {
    .forMeImg {
        margin-top: 10vw;
        margin-bottom: 15vw;
    }

    .forMeContent {
        padding-left: 10%;
        padding-right: 10%;
    }
}