.RootProgram {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: none;
    padding-top: 100px;
    padding-bottom: 100px;

}

@media (max-width: 991px) {
    .RootProgram {
        height: auto;
        padding-left: 0;
        padding-right: 0;
        padding-top: 25vw;
        padding-bottom: 15vw;
    }
}