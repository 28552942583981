.RootPrices {
   background-color: theme-color("bgColor");
   height: auto;
   max-width: 1440px;
   // width: 80%;

}

@media (max-width: 991px) {
   .RootPrices {
      height: auto;
   }
}