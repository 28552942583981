.RootEarn {
    background-color: theme-color("bgColor");
    height: auto;
    max-width: 1440px;
    padding-top: 200px;
    padding-bottom: 200px;

}

@media (max-width: 991px) {
    .RootEarn {
        padding-top: 25vw;
        padding-bottom: 15vw;

        height: auto;
    }
}