.uniqueSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.uniqueImg {
    width: 100%;
    transform: rotate(-3deg) !important;
}

.uniqueContent .h2Title,
.uniqueContent .pText {
    text-align: left;
}

.uniqueContent .pText {
    margin-top: 40px;
}

.uniqueContent .whiteLine {
    margin-top: 30px;
    // margin-bottom: 100px;
}

.uniqueDivContent {
    margin-bottom: 100px;

}

.uniqueDivContent:nth-last-child(1) {
    margin-bottom: 0;
}

.uniqueColButton {
    display: flex;
    justify-content: center;
}

.uniqueSectionRow .h2Title {
    margin-bottom: 80px;
}

.buttonsUnique {
    margin-top: 100px;
    padding: 20px 60px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.5s;
    letter-spacing: 1.82px;
    -webkit-text-decoration: none !important;

    text-decoration: none !important;

    font-family: 'league-gothic';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    //  line-height: 75px;
    /* or 469% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
}

@media(max-width: 991px) {
    .uniqueContent .h2Title {
        margin-top: 20vw;
        font-size: 8vw !important;
        line-height: 8vw !important;
        margin-bottom: 0;
    }

    .uniqueContent {
        padding-left: 10%;
        padding-right: 10%;
    }

    .buttonsUnique {
        margin-top: 10vw;
        padding: 4vw 8vw;

        font-size: 3vw;
    }

    .uniqueContent .pText {
        margin-top: 5vw;
    }

    .uniqueDivContent {
        margin-bottom: 10vw;
    }

    .uniqueContent .whiteLine {
        margin-top: 5vw;
        // margin-bottom: 100px;
    }

}

@media(max-width: 666px) {
    .buttonsUnique {
        margin-top: 10vw;
        padding: 6vw 10vw;

        font-size: 5vw;
    }



}