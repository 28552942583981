* {
  font-family: 'league-gothic';
  // transition: 0.3s;
}

// * span {
//   font-family: "Blackink";
// }

* span::after {
  font-family: 'league-gothic';
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

button:focus {
  outline: none;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body {
  color: theme-color('textColor');
}

body,
html {
  font-family: 'league-gothic';

  background-color: #000;
  cursor: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.h1Title {
  font-style: normal;
  font-weight: normal;
  font-size: 146px;
  line-height: 146px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

.h2Title {
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 64px;
  /* or 64px */

  text-align: center;
  text-transform: uppercase;
}

.h3Title {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 23px;
}

.pText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;
}

.buttonText {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  text-align: center;
  text-transform: uppercase;
}

.namesTitle {
  font-family: 'Blackink';
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 51px;
  /* or 50px */
  text-align: center;
}

// CURSOR
.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 150ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode;
}

.cursor--hidden {
  opacity: 0;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}

.cursor--img-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}

a {
  text-decoration: underline;
  // color: #fefefe;
}

a:link {
  -webkit-text-decoration: none;
  text-decoration: none;
}

a:hover {
  color: #fff;
}

.canvasStyle {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // z-index: 1;
}

.boldText {
  color: transparent !important;
  -webkit-text-stroke: 1px #fff;
}

// SKEW SCROLL
.data-scroll-content-style {
  height: 100%;
}

// TEXTURE
.textureBack {
  position: fixed;

  background-image: url(../../assets/img/photos/Textura---Twerkealo-min.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

// LINE
.whiteLine {
  background-image: url(../img/icons/Linea---Twerkealo.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1px;
}

.topLine {
  background-image: url(../img/icons/Linea---Twerkealo.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.bottomLine {
  background-image: url(../img/icons/Linea---Twerkealo.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.leftLine {
  background-image: url(../img/icons/Linea--V-Twerkealo.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: inherit;
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
}

.rightLine {
  background-image: url(../img/icons/Linea--V-Twerkealo.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
}

// BUTTON PRICES
.buttonImage {
  background: transparent;
  border: none;
  background-image: url(../../assets/img/icons/Boton---Twerkealo.svg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;

  text-transform: uppercase;
}

.buttonImage2 {
  background: transparent;
  border: none;
  background-image: url(../../assets/img/icons/Boton---Twerkealo_W.svg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;

  text-transform: uppercase;
}

// STICKY TOP NAV
.stickyTopNav {
  position: sticky;
  top: 130px;
}


// POPUP
.popUpContainer .h2Title {
  text-align: left;
  font-size: 35px;
  line-height: 35px;
}

.popUpContainer .h3Title {
  font-size: 30px;
  line-height: 30px;
}

.popUpButton {
  letter-spacing: 0.05em;
  color: #fff;
  margin-top: 30px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 15px;
}

// ANIMATIONS
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

//  Discount
.striked-price {
  position: relative;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-family: 'Blackink';
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 100%;
  /* or 126px */

  text-align: center;
}

.striked-price:before {
  position: absolute;
  align-content: center;
  content: '';
  width: 124px;
  top: 50%;
  left: -10px;
  border-top: 4px solid;
  border-color: inherit;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
  color: #dd0000 !important;
  opacity: 1;
}

// FOLLOW MOUSE BUTTON

// ANIMATIONS
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}

@media (min-width: 1440px) {
  .h2Title {
    font-size: 69px;
    line-height: 60px;
    text-transform: uppercase;
  }
}

@media (min-width: 1440px) and (max-height: 800px) {
  .h2Title {
    font-size: 8vh !important;
    line-height: 8vh !important;
    text-transform: uppercase;
  }

  .pText {
    font-size: 3vh !important;
  }

  // .h2Title {
  //   font-size: 89px;
  //   line-height: 80px;
  //   text-transform: uppercase;
  // }
}

@media (max-width: 1440px) and (min-width: 991px) {
  .h2Title {
    font-size: 4vw !important;
    line-height: 4vw !important;
    text-transform: uppercase;
  }



  .buttonText {
    font-size: 1vw;
    line-height: 2vw;
  }

  .popUpButton {
    font-size: 1.7vw;
  }

}

@media (max-width: 1440px) and (min-width: 1100px) {
  .pText {
    font-size: 1.2vw !important;
    line-height: 1.5vw;
  }
}

@media (max-width: 1100px) and (min-width: 991px) {
  .pText {
    font-size: 1.4vw !important;
    line-height: 1.8vw;
  }
}

@media (max-width: 991px) {
  .h2Title {
    font-size: 10vw;
    line-height: 10vw;
    text-transform: uppercase;
  }

  .pText {
    font-size: 2vw;
    line-height: 3vw;
  }

  .h3Title {
    font-size: 4vw;
    line-height: 5vw;
  }

  // POPUP
  .popUpContainer .h2Title {
    text-align: left;
    font-size: 5vw;
    line-height: 5vw;
  }

  .popUpContainer .h3Title {
    font-size: 3vw;
    line-height: 3vw;
  }

}

@media (max-height: 700px) and (min-width: 991px) {
  // .h2Title {
  //   font-size: 59px;
  //   line-height: 50px;
  //   text-transform: uppercase;
  // }
  // .pText {
  //   font-size: 2vw;
  // }
}

@media (max-width: 666px) {

  // .h2Title {
  //   font-size: 14vw;
  //   line-height: 13vw;
  //   text-transform: uppercase;
  // }
  .h3Title {
    font-size: 5vw;
    line-height: 6vw;
  }

  .pText {
    font-size: 3vw;
    line-height: 4vw;
  }

  // POPUP
  // POPUP
  .popUpContainer .h2Title {
    text-align: left;
    font-size: 9vw;
    line-height: 9vw;
  }

  .popUpContainer .h3Title {
    font-size: 7vw;
    line-height: 7vw;
  }

}

@media (max-width: 550px) {

  // .h2Title {
  //   font-size: 14vw;
  //   line-height: 13vw;
  //   text-transform: uppercase;
  // }
  .h3Title {
    font-size: 5vw;
    line-height: 6vw;
  }

  .pText {
    font-size: 3.5vw;
    line-height: 4.5vw;
  }
}

@media (max-width: 400px) {
  // .h2Title {
  //   font-size: 8vw;
  //   line-height: 8vw;
  //   text-transform: uppercase;
  // }
}

// ANIMATIONS
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}