/* GENERAL STYLE */

.pricesContainer {
   height: auto;
   padding-top: 200px;
   padding-bottom: 200px;
   min-height: 500px;
}

.bodyCardPrices {
   margin: 0 auto;
   display: flex;
   align-items: center;
   margin-top: 20px;
}

.pricesSectionTitle {
   color: #fff;
   margin: 0 auto;
   margin-bottom: 100px;
}

.pricesCol {}

.pricesCol .card-deck {
   width: 100%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
}

.iconPricesTop {
   // position: absolute;
   // left: 0;
   // right: 0;
   // top: 20px;
   width: fit-content;
   padding-left: 40px;
   padding-right: 40px;
   padding-top: 50px;
   height: fit-content;
   margin: 0 auto;
   transition: 0.3s;
   display: flex;
   align-items: center;
}

.iconPricesTop h2 {
   display: block;
}

.iconPricesTop .h3Title {
   font-family: "league-gothic";
   font-style: normal;
   font-weight: normal;
   font-size: 40px;
   line-height: 100%;
   /* or 40px */

   text-align: center;
   text-transform: uppercase;
}

.iconPricesBottom {
   background: transparent;
   width: 273px;
   height: 80px;
   border-radius: 0;
   display: flex;
   align-items: center;
   margin: 0 auto;
   transition: 0.3s;
   position: absolute;
   bottom: -40px;
   left: 0;
   right: 0;

   z-index: 99;

   display: flex;
   justify-content: center;
}

.iconPricesBottom a {
   //  margin: 0 auto;
}

.cardStyle {
   flex: none !important;
   width: 447px;
   height: 539px;
   background: transparent;
   padding: 0 30px 0 30px;
   border: none;
   display: flex;
   border: 0.5px solid rgba(0, 0, 0, 0.1);
   color: #fff;
}

.cardStyle .hrLine {
   width: 15%;
   height: 5px;
   background-color: theme-color('primary');
   margin: 20px auto;
   border-radius: 50px;
}

.pricesTexts {
   position: relative;
   margin-top: 30px;
}

.beforePrice {
   font-family: "Blackink";
   font-style: normal;
   font-weight: normal;
   font-size: 80px;
   line-height: 100%;
   /* or 126px */
}

.before {
   //FONT
   font-family: Roboto;
   font-style: normal;
   font-weight: normal;
   font-size: 10px;
   line-height: 30px;
   letter-spacing: 0.2em;
   text-transform: uppercase;
   //POSITION
   position: absolute;
   top: -18px;
   left: 0;
   right: 0;
}

.afterPrice {
   font-family: "Blackink";
   font-style: normal;
   font-weight: normal;
   font-size: 126px;
   line-height: 100%;
   /* or 126px */

   text-align: center;

   margin-top: 35px;
   animation: heartbeat 1s infinite;
}

.after {
   //FONT
   font-family: "league-gothic";
   font-style: normal;
   font-weight: normal;
   font-size: 20px;
   line-height: 100%;
   letter-spacing: 0.2em;
   text-transform: uppercase;
   //POSITION
   position: absolute;
   top: 100px;
   left: 0;
   right: 0;
}

/* BUTTONS STYLE */
.contentPrices .buttonsGroup {
   margin-top: 30px;
   display: flex;
}

.buttonsPrices {
   padding-left: 80px;
   padding-right: 80px;
   width: fit-content;
   height: 50px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   transition: 0.5s;
   letter-spacing: 1.82px;
   -webkit-text-decoration: none !important;

   text-decoration: none !important;

   display: flex;
   align-items: center;
   text-align: center;
   text-transform: uppercase;
}

.iconPricesBottom .buttonImage {
   font-family: 'league-gothic';
   font-style: normal;
   font-weight: bold;
   font-size: 25px;
   line-height: 75px;

   // margin-right: 60px;
   // background: theme-color('textColor') !important;
   color: #fff;
   border: none;
   transition: 0.5s;
}

.iconPricesBottom .buttonImage:hover {
   color: theme-color('buttonText') !important;
}

.iconPricesBottom .buttonImage:hover::before {
   border-bottom-color: theme-color('buttonText') !important;
   border-right-color: theme-color('buttonText') !important;
}

.iconPricesBottom .buttonImage:hover::after {
   border-top-color: theme-color('buttonText') !important;
   border-left-color: theme-color('buttonText') !important;
}

.paymentTypeTitle {
   font-family: "Roboto";
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 100%;
   /* or 18px */

   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   letter-spacing: 0.2em;
   text-transform: uppercase;
}

@media (min-width: 991px) {
   .paymentContainer {
      max-width: none !important;
   }

   .cardStyle {
      margin: 0 auto !important;

      // width: 410px;
      // height: 508px;
      padding-left: 0;
      padding-right: 0;
   }
}

@media (max-width: 1200px) {

   .cardStyle {
      margin: 0 auto !important;

      width: 410px;
      height: 508px;
      padding-left: 0;
      padding-right: 0;
   }
}

@media (max-width: 991px) {

   // FONTS
   .iconPricesTop .h3Title {
      font-size: 5vw;
      line-height: auto;
   }

   //FONT
   .paymentTypeTitle {
      font-size: 2vw;
   }


   // 

   .pricesContainer {
      padding-top: 20vw;
      padding-bottom: 20vw;
   }

   .iconPricesTop {
      padding-top: 0;
   }

   .pricesCol .card-deck {
      margin: 0 auto !important;
   }

   .pricesCol .card-deck .card {
      margin-bottom: 30px;
   }

   .cardStyle {
      border-radius: 0;
   }

   .pricesSectionTitle {
      margin-bottom: 50px;
   }

   .iconPricesBottom {
      bottom: -40px;
      width: fit-content;
   }

   .buttonsPrices {
      width: 70%;
   }

   .pricesContainer {
      height: auto;
   }

   .pricesCol .card-deck {
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
   }

   .cardPrices .container {
      margin: 0;
      padding: 0;
      max-width: 100%;
   }

   .card {
      flex: none !important;
   }

   .cardStyle {
      margin: 0 auto !important;

      width: 80%;
      height: fit-content;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 7vw;
      padding-top: 7vw;
   }

   .cardStyle:nth-child(1) {
      margin-bottom: 80px !important;
   }

   .cardStyle:nth-child(odd) {
      margin-right: 0;
      margin-left: 0;
   }

   .cardStyle:nth-child(even) {
      margin-left: 0;
      margin-right: 0;
   }

   .pricesCol .card-deck {
      display: block;
      justify-content: center;
   }

   .card-deck .card {
      margin-bottom: 0;
   }

   .pricesCol {
      padding: 0;
   }

   .bodyCardPrices {
      display: block;
      align-items: center;
      padding: 0;
      margin-top: 0;
   }

   .pricesTexts {
      width: 100%;
      margin: 0 auto;
   }
}

@media (max-width: 600px) {

   //FONT
   .paymentTypeTitle {
      font-size: 3vw;
   }


   .afterPrice {
      font-family: "Blackink";
      font-style: normal;
      font-weight: normal;
      font-size: 20vw;
      line-height: 100%;

   }

   .after {

      font-family: "league-gothic";
      font-style: normal;
      font-weight: normal;
      font-size: 3vw;
      line-height: 100%;
      letter-spacing: 0.2em;
      text-transform: uppercase;

   }

   .beforePrice .striked-price {

      font-size: 10vw;

   }

   .beforePrice .striked-price:before {
      position: absolute;
      align-content: center;
      content: '';
      width: 130%;
      top: 50%;
      left: -10px;
      border-top: 4px solid;
      border-color: inherit;
      transform: rotate(-15deg);
      color: #dd0000 !important;
      opacity: 1;
   }

   .before {
      //FONT
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 30px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      //POSITION
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
   }

}

@media (max-width: 400px) {
   .iconPricesBottom .buttonImage {
      font-size: 5vw;
      line-height: 5vw;
   }

   .buttonsPrices {
      padding-left: 10vw;
      height: fit-content;
      padding-right: 10vw;
      padding-top: 3vw;
      padding-bottom: 3vw;
   }

}