/* BACKGROUND ROW */

.RootFaq {
   height: auto;
   background-color: #000;
   padding-top: 5vw;
   padding-bottom: 10vw;
}

/* CONTENT */
.RootFaq-inside {
   position: relative;
   margin: 0 auto;
   width: 100%;
   //  padding-top: 100px;
   // padding-bottom: 200px;
}

@media (max-width: 1000px) {
   .RootFaq {
      height: auto;
      padding-top: 15vw;
      padding-bottom: 0px;
   }

   .RootFaq-inside {
      // padding-top: 700px;
      // padding-bottom: 20vw;
   }
}