/* BACKGROUND ROW */
/* .Root {
    position: relative;
} */
.RootFooter {
   background-color: theme-color("bgColor");
   max-width: 1440px;
   padding: 50px;
}

/* CONTENT */
.RootFooter-inside {
   /* position: relative; */
   width: 100%;
   // margin: 0 auto;
   display: flex;
   // justify-content: center;
}

// BUTTONS
.socialButtonsGroup {
   display: flex;
   justify-content: center;
   margin-bottom: 30px;
   font-family: 'league-gothic';
   font-style: normal;
   font-weight: bold;
   font-size: 16px;
   line-height: 75px;

   text-align: center;
   text-transform: uppercase;
}

.copyTextGroup {
   margin: 0 auto;
   text-align: center;
}

.listGroupItemFooter a {
   -webkit-text-decoration: none;
   text-decoration: none;
   color: #fff;
   transition: 0.3s;
}

.socialButtonsToolbar {
   display: flex;
   align-items: center;
   text-align: center;
}

.socialButtons {
   margin-left: 20px;
}

.socialButtons button {
   width: 60px;
   height: 60px;
   border-radius: 50%;
   color: theme-color('footerColor');
   background-color: theme-color('footerColor');
   border: none;
   box-shadow: 0 6px 10px rgba($color: #ffffff, $alpha: 0.5);
}

.socialIconStyle {
   font-size: 1em;
   color: theme-color('primary');
   transition: 0.3s;
}

.backButton:hover .socialIconStyle {
   color: #fff;
}

.copyText {
   color: theme-color('footerColor');
   display: block;
}

.brandFooter {
   color: #fff;
}

.logoFooter {
   font-size: 74px;
   line-height: 80px;
   margin-bottom: 20px;
}

.listGroupFooter {
   width: fit-content;
}

.footerLinks {
   display: flex;
   justify-content: center;
   // align-items: center;
}

.listGroupItemFooter {
   background-color: transparent;
   border: none;
   padding: 0;
   padding-bottom: 0.3em;
   text-align: left;
   margin-bottom: 0.5em;

   width: fit-content;
}

.listGroupItemFooter a {
   font-family: "Roboto";
   font-style: normal;
   font-weight: normal;
   font-size: 16px;

}

.listGroupTitle {
   font-family: "league-gothic" !important;
   font-style: normal;
   font-weight: normal;
   font-size: 18px !important;
   line-height: 22px !important;

   margin-bottom: 1.6em;


   padding: 0;
   //  padding-bottom: 1em;

   display: flex;
   align-items: center;
   text-transform: uppercase;
}

/* UNDERLINE EFFECT */
.justify-content-center {
   margin: 0 auto;
}

.listGroupText {}

.listGroupText a {
   // position: absolute;
   transition: 0.5s;
   // width: 100%;
}

.listGroupText a::after {
   position: absolute;
   content: '';
   width: 100%;
   height: 2px;
   top: 100%;
   left: 0;
   background: theme-color('primary');
   transition: transform 0.5s;
   transform: scaleX(0);
   transform-origin: right;
}

.listGroupText a:hover::after {
   transform: scaleX(1);
   transform-origin: left;
}

.listGroupText a:focus::after {
   transform: scaleX(1);
}

.poweredSection {
   margin-top: 100px;
}

.powered {
   font-family: 'league-gothic';
   font-style: normal;
   font-weight: 300;
   font-size: 16px;
   /* or 24px */

   display: block;
   text-align: center;
   color: #fff;
}

.powered a:hover {
   -webkit-text-decoration: none;

   text-decoration: none;
}

.poweredSection .hrLine {
   width: 50px;
   height: 1px;
   margin: 0 auto;
   margin-top: 20px;
   margin-bottom: 10px;
   background-color: rgba(255, 255, 255, 0.295);
}

@media (max-width: 991px) {
   .RootFooter-inside {
      width: 100%;
      display: block;
      justify-content: center;
   }

   .RootFooter {
      // padding: 50px 10px;
      display: flex;
      justify-content: center;
   }

   .footerLinks {
      // width: fit-content;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
   }

   .listGroupFooter {
      width: fit-content !important;
      margin: 0 auto;
   }

   .listGroupItemFooter {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
   }

   .listGroupItemFooter:nth-child(1),
   .listGroupItemFooter:nth-last-child(5) {
      margin-bottom: 15px !important;
   }

   .socialButtons button {
      width: 50px;
      height: 50px;
   }

   .socialButtonsToolbar .btn {
      padding: 0;
   }

   .legalContainer {
      margin-top: 50px;
   }

   .copyText:nth-child(1) {
      margin-bottom: 10px;
   }

   .brandFooter {
      margin-bottom: 50px;
   }

   .interestLinks {
      margin-bottom: 30px;
   }

   .logoFooter {
      display: flex;
      justify-content: center;
   }

   .brandLogo {
      width: 100%;
      max-width: 400px;
   }

   .poweredSection {
      margin-top: 50px;
   }

   .listGroupText {
      margin-bottom: 10px !important;
   }

   .listGroupItemFooter a {

      font-size: 5vw;
      line-height: 6vw;
   }
}