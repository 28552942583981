.videoSectionRow {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: #fff;
}

.videoCol {
  height: 100%;
  width: 100%;
}

.responsiveEmbed {
  margin-top: 50px;
}