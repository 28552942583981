.EarnSectionRow {
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.earnImg {
    width: 100%;
    transform: rotate(-3deg) !important;
}

.earnContent .h2Title,
.earnContent .pText {
    text-align: left;
}

.earnContent .pText {
    width: 80%;
    margin: 0 auto;
}

.earnButton {
    color: #fff;
    padding: 20px 40px 20px 40px;
    position: relative;
    top: -10px;
}

// TIME LINE STYLE
.verticalTimeLineStyle::before {
    background-color: transparent;
    background-image: url(../../../../assets/img/icons/Linea--V-Twerkealo.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 2px;
}

.vertical-timeline-element-icon {
    background-color: theme-color('primary');
    position: relative;
    box-shadow: none;
    width: 13px !important;
    height: 13px !important;
    margin-left: -8px !important;
    margin-top: 50px;
    margin-bottom: 50px;
}

.vertical-timeline-element-content {
    box-shadow: none;
    text-align: center;
    background: transparent;
}

.vertical-timeline-element {
    display: flex;
    align-items: center;
}

.rightFloating .vertical-timeline-element-content {
    position: absolute;
    right: 0;
}

.vertical-timeline-element-content .pText {
    text-align: left;
}

.vertical-timeline-element-date {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: auto !important;
    right: 124% !important;
    text-align: right !important;

    font-family: "Blackink";
    font-style: normal;
    font-weight: normal;
    font-size: 50px !important;
    line-height: 100%;
    /* or 50px */
    text-transform: uppercase;
    padding-right: 0.75em !important;
}

.vertical-timeline-element-content-arrow {
    display: none;
}

@media (max-width: 1169px) and (min-width: 991px) {

    .vertical-timeline::before {

        left: 45% !important;

    }

    // .vertical-timeline-element-content .pText {
    //     font-size: 1vw !important;
    // }

    .vertical-timeline-element-icon {
        left: 4px;
    }

    .vertical-timeline-element-icon {
        margin-left: -5px !important;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content {
        margin-left: 0;
        padding: 1.5em;
        width: 44%;
    }

    .rightFloating .vertical-timeline-element-content {
        right: 0 !important;
    }

    .vertical-timeline-element-date {
        float: left;
        position: absolute;
    }

    .vertical-timeline-element {
        width: 90%;
    }

    .vertical-timeline-element {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 6em 0 !important;
    }


    // .rightFloating .vertical-timeline-element-content {
    //     width: 44%;
    // }

    // .vertical-timeline::before {

    //     left: 50%;

    // }

    // .vertical-timeline-element-icon {
    //     left: auto;
    // }



}

@media (max-width: 991px) {
    .earnContent .pText {
        width: 100%;
    }

    .vertical-timeline-element-content {
        margin-left: 10vw;
    }

    .vertical-timeline-element-content .vertical-timeline-element-date {
        padding: 0;
        // display: none;
        position: absolute;
    }

    .vertical-timeline-element-content .pText {
        margin-bottom: 30px;
    }

    .rightFloating .vertical-timeline-element-content {
        right: auto;
    }

    .vertical-timeline-element-icon {
        left: 21px;
    }

    .vertical-timeline {
        width: 60%;
    }
}

@media (max-width: 991px) {
    .earnImg {
        width: 80%;
        margin-top: 5vw !important;
        margin-bottom: 5vw;
    }

    .verticalTimeLineStyle {
        margin-bottom: 50px;
    }

    .earnButton {
        margin-top: 30px;
        top: 0;
        font-size: 2vw;
        // padding: 6vw 10vw;

    }

    .earnContent .pText {

        margin-bottom: 40px;
        margin-top: 40px;
    }


}

@media (max-width: 700px) {
    .earnButton {
        margin-top: 30px;
        padding: 6vw 10vw;

        font-size: 5vw;
    }
}

@media (max-width: 600px) {
    .vertical-timeline {
        width: 70%;
    }
}

@media (max-width: 400px) {
    .vertical-timeline {
        width: 80%;
    }
}

@media (max-width: 300px) {
    .vertical-timeline {
        width: 90%;
    }
}